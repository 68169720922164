import React from 'react';

import ImageBox from './ImageBox';

const HomeProjects = ({projects, filter}) => {
    
    return projects ?     
    (
        <div className="container">
            <div className="row imageAlbum">
            {
              
              projects.map( (oneProject) => {
  
                let item = {
                  size: oneProject.cover_size_homepage,
                  orientation: 'wide',
                  path: oneProject.cover_image
                }

                return <ImageBox key={oneProject.id} project={oneProject} filter={filter} {...item} />
              })
            }
            </div>
        </div>
    ) :
    null;
}

export default HomeProjects;