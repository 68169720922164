import React, {Component} from 'react';
import Header from './Header';


import HomeProjects from './HomeProjects';
import projects from "./projects.json";

class Home extends Component {
  
    constructor(props){
      super(props);
  
      this.state = {
        projects: projects,
        currentFilter: null
      }

      if( this.props.match.params.hasOwnProperty('filter') ){
          this.state.currentFilter = this.props.match.params.filter;
      }

      this.handleSetFilter = this.handleSetFilter.bind(this);
    }
  
    componentDidUpdate(prevProps, prevState) {
        if( prevProps.match.params.filter !== this.props.match.params.filter){
            //Perform some operation here
            this.handleSetFilter(this.props.match.params.filter);
        }
    }

    handleSetFilter( filterName ) {
      this.setState({currentFilter: filterName});
    }
  
    render() {
  
      return (
        <div>
          <Header onSetFilter={this.handleSetFilter} {...this.props.match} />
          <HomeProjects projects={this.state.projects} filter={this.state.currentFilter} />
        </div>
      ); 
    }
  }
  
  export default Home;