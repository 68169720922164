import React from 'react'; 
import BeardedGallery from './BeardedGallery';

import { Link } from "react-router-dom";

const Footer = () => {

    let footerImages = [
        '/images/footer/lounge.jpg',
        '/images/footer/bike-house.jpg',
        '/images/footer/tables.jpg'
    ];

    return (
        <div className="footer">
        <div className="footer__text row">
            <div className="col-md-6">
                <p className="footer__pride">
                    We pride ourselves in producing quality content and going the full mile. 
                    <br />
                    That's the Bearded Way.
                </p>
                <p className="footer__start-project">
                    <span className="footer__pride--orange">Let's Start a Project.</span>
                </p>
            </div>
            <div className="col-md-6">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <p className="footer__connect">
                            <span className="footer__title">Connect with us</span>
                            <span className="footer__social">
                                <Link to="/"><i className="fa fa-facebook" aria-hidden="true"></i></Link>
                                <Link to="/"><i className="fa fa-twitter" aria-hidden="true"></i></Link>
                                <Link to="/"><i className="fa fa-linkedin" aria-hidden="true"></i></Link>
                                <Link to="/"><i className="fa fa-instagram" aria-hidden="true"></i></Link>
                            </span>
                            <span className="clearfix"></span>
                        </p>
                        <p className="footer__get-in-touch">
                            <span className="footer__title">Get in touch</span>
                            0161 870 2000 
                            <br />
                            ideas@beardedfellows.co.uk
                        </p>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <p className="footer__findus">
                            <span className="footer__title">Find us</span>
                            Bridgeworks 
                            <br />67 Bridge Street 
                            <br />M3 3BQ 
                            <br />Manchester
                        </p>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
        </div>

        <div className="footer__slides row">
            <BeardedGallery imageArray={footerImages} />
        </div>

        <div className="footer__signature">
            <h3>BF</h3>
            <p>Copyright &copy; 2019 Bearded Fellows</p>
        </div>
        
        </div>
    );
}

export default Footer;