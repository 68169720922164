import React, { Component } from 'react';
import { Link } from "react-router-dom";


export default class ProjectHeader extends Component {


  render() {
    
    let isMobile = false;
    let menuExtraClass = !isMobile ? 'd-none' : ''; 
    
    let projectCoverPath = this.props.project ? this.props.project.cover_image : '';
    console.log(this.props);

    return (
        <div className="project-header">
            <div className="project-cover">
                <img src={projectCoverPath} alt="" />
                <div className="project-cover__overlay">
                    <div className="container">
                        <div className={`header__text`}>
                            <h1><Link to="/">Bearded Fellows</Link></h1>
                        </div>
                        <div className="project-title">
                            <h2>{this.props.project.name}</h2>
                            <p>{this.props.project.subtitle}</p>
                        </div>
                        
                        <div className={`header__menu ${menuExtraClass}`}>
                            <ul>
                                <li><Link to="/work"  className="underline">Work</Link></li>
                                <li><Link to="/about" className="underline">About</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            <span className="spike white" />
            <span className="spike black" />
        </div>
    );
  }
}