import React, {Component} from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import Route from "react-router-dom/Route"

 
import Header from './Header';
import Footer from './Footer';

import Home from './Home';
import Project from './Project';


import './App.css';

class App extends Component {

  state = {
    loading: true
  }

  

  componentDidMount() {
    demoAsyncCall().then(() => this.setState({ loading: false }));
  }

  render(){

    const { loading } = this.state;
    
    return loading ? null : (
        <Router>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/show/:filter" component={Home} />
          <Route path="/project/:id" component={Project} />

          <div className="container">
            <Footer />
          </div>
          
        </Router>
      );
  }
}

const About = (props) => {
  return (
      <div>
        <Header hideFilters={true} {...props.match}  />
      </div>
  ); 
}

function demoAsyncCall() {
  return new Promise((resolve) => setTimeout(() => resolve(), 2000));
}

export default App;
