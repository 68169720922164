import React, { Component } from 'react';
import {
    BrowserView,
    MobileView,
    isMobile
  } from "react-device-detect";
import {default as UUID} from "node-uuid";

import Album from './Album';
import Header from './Header';
import ProjectHeader from './ProjectHeader';
import VideoGallery from './VideoGallery';

import projects from "./projects.json";

import HomeProjects from './HomeProjects';

const getProjectFromParams = ( match ) => {
    // let {match} = this.props;
    let matchProjects = projects.filter( (project) => parseInt(project.id) === parseInt(match.params.id) );
    return matchProjects.length > 0 ? matchProjects[0] : projects[0]; // failsafe
}

class Project extends Component { 
    
    constructor(props){
        super(props);
        this.state = {
            project: {},
            descriptionExtended: false
        }


        this.renderSection = this.renderSection.bind(this);
        this.renderSectionAlbum = this.renderSectionAlbum.bind(this);
        this.renderSectionTestimonial = this.renderSectionTestimonial.bind(this);
        this.renderSectionCredits = this.renderSectionCredits.bind(this);
        // this.getProjectFromParams = this.getProjectFromParams.bind(this);

        this.state.project = getProjectFromParams( this.props.match );
    }


    componentDidUpdate(prevProps, prevState) {
        if(prevProps.match.params.id !== this.props.match.params.id){
            //Perform some operation here
            let pProject = getProjectFromParams(this.props.match);
            this.setState( {project: pProject} );
            window.scrollTo(0, 0);
        }
    }



    renderSection( section ){
        
        switch(section.type){
            case 'album': return this.renderSectionAlbum(section); 
            case 'testimonial': return this.renderSectionTestimonial(section); 
            case 'credits': return this.renderSectionCredits(section); 
            default: break;
        }
        
    }

    renderSectionAlbum( section ){
        return (
            <section className="project-section" key={UUID.v4()}>
                <div className="project-section__title with-left-line">
                    <h3>{section.title}</h3>    
                </div>

                <Album section={section} />
            </section>
        )
    }

    renderSectionTestimonial( section ){

        return (
            <section className="project-section" key={UUID.v4()}>
                <div className="project-section__testimonial">
                    <div className="project-section__text">
                        "{section.text}"
                    </div>
                    <div className="project-section__author">
                        - {section.author}
                    </div>
                </div>
            </section>
        );
    }

    renderSectionCredits( section ){

        return (
            <section className="project-section" key={UUID.v4()}>
                <div className="project-section__credits">
                    <BrowserView>
                        <h3>Credits</h3>
                        <hr />    
                    </BrowserView>
                    <MobileView>
                        <div className="project-section__title with-left-line">
                            <h3>Credits</h3>
                        </div>
                    </MobileView>
                    {
                        section.items.map( (credit, index) => {
                            return (
                                <div className="credit-line row" key={`${index}-${credit.part}`}>
                                    <div className="col credit-part">{credit.part}</div>
                                    <div className="col credit-name">
                                    {credit.name}
                                    </div>
                                </div>
                            )
                        })
                    }
                    <hr />
                </div>
            </section>
        );
    }

    render (){

        let descriptionExtendedClass = this.state.descriptionExtended === true ? 'project-description__extended--visible' : 'project-description__extended--hidden';
        let isMinusClass = this.state.descriptionExtended === true ? 'opened' : 'closed';
        let isMobileClass = isMobile ? 'isMobile' : '';

        return (
        <div>
            <BrowserView>
                <ProjectHeader project={this.state.project} />
            </BrowserView>

            <MobileView>
                <Header />
            </MobileView>

            <div className={`container project-container ${isMobileClass}`}>
                <VideoGallery itemArray={this.state.project.video_gallery} />
                
                
                <section className="project-section project-section--description">
                    <div className="project-section__title with-left-line">
                        <p>{this.state.project.subtitle}</p>
                        <h3>{this.state.project.name}</h3>    
                    </div>
                    <div className="project-section__content project-description">
                        <p>
                            {this.state.project.description.main}
                        </p>
                        <p className={`project-description__extended ${descriptionExtendedClass}`}>
                            {this.state.project.description.extended}
                        </p>

                        <div className={`circle-plus ${isMinusClass}`}
                            onClick={() => this.setState({descriptionExtended: !this.state.descriptionExtended})}>
                            <div className="circle">
                                <div className="horizontal"></div>
                                <div className="vertical"></div>
                            </div>
                        </div>
                            
                    </div>
                </section>

                {
                    this.state.project.sections.map( (section) => this.renderSection(section))
                }

                
                
            </div>
            <HomeProjects projects={projects} filter={null} />
        </div>
        );
    }
}



  export default Project;