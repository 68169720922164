import React from 'react';
import { Link } from "react-router-dom";

const ImageBox = ({size, orientation, path, project, filter}) => {
  
    // let project = project || {type: null, name: '', subtitle: ''};


    if ( size === 'undefined' ){
        size  = [1,1];
    }
    orientation = orientation || "wide";
  
    // console.log("<ImageBox with ", path);
  
    // TODO: calculate Height based on viewPORT!!!! 
    let height = size[1]* ( (orientation !== 'wide') ? 250 : 300);
  
    return (
        <div className={`box-${size[0]} imageBox`} 
            style={{height: height, backgroundImage: `url(${path})`}}>
            {
               (project && project.name !== '') ? renderProjectInfo({filter, project}): null
            }
        </div> 
    );
}
  
const renderProjectInfo = ({filter, project}) => {
    
    let filterOutClass = (filter !== null && filter !== 'all' && project.type !== filter) ? 'filter-out' : '';
    return (
        // <Link to={`/project/${project.id}`}>
            <div className={`home-project__mask ${filterOutClass}`}>
            <Link to={`/project/${project.id}`}>
                <div className="home-project__overlay">
                    <p className="home-project__subtitle">{project.subtitle}</p>
                    <hr />
                    <p className="home-project__name">{project.name}</p>
                </div>
            </Link>
            </div>
        // </Link>
    )
} 


export default ImageBox;