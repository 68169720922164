import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

export default class Header extends Component {
  constructor(props){
      super(props);
      console.log("in HEader=", this.props);

      this.selectedMenuItem = { 
        work: 'active',
        about: ''
      };

      this.state = {
          all: '',
          animation: '',
          live_action: '',
          mixed_media: '' 
      }

      if( this.props.hasOwnProperty('path') ){
        if( this.props.path.includes('about') ){
            this.selectedMenuItem = {
                work: '',
                about: 'active'
            }
        }
      }

      this._setActiveFilter = this._setActiveFilter.bind(this);
      this.renderFilters = this.renderFilters.bind(this);
  }

  componentDidMount(){
    this._setActiveFilter();
  }

  componentDidUpdate(prevProps, prevState){
      if( this.props.hasOwnProperty('url') && this.props.url.includes('/show') && prevProps.url !== this.props.url ){
        this._setActiveFilter();
      }
  }

  _setActiveFilter(){

    let emptyState = {
        all: '',
        animation: '',
        live_action: '',
        mixed_media: '' 
    }

    if( this.props.hasOwnProperty('url') && (this.props.url.includes('show') || this.props.url === '/') ){
        switch( this.props.url ){
            case '/show/animation': 
               this.setState({
                ...emptyState,   
                animation: 'active'
               }); break;
            case '/show/live-action': 
               this.setState({
                ...emptyState,   
                live_action: 'active'
               }); break;
            case '/show/mixed-media': 
               this.setState({
                ...emptyState,   
                mixed_media: 'active'
               }); break;
            default: this.setState({
                ...emptyState,   
                all: 'active'
               }); break;
        }
    }
  }

  renderFilters(){
      return this.props.hideFilters ? 
      null
        :
      (<ul>
          <li><Link to="/show/all" className={`underline ${this.state.all}`}>All</Link></li>
          <li><Link to="/show/animation" className={`underline ${this.state.animation}`}>Animation</Link></li>
          <li><Link to="/show/live-action" className={`underline ${this.state.live_action}`}>Live Action</Link></li>
          <li><Link to="/show/mixed-media" className={`underline ${this.state.mixed_media}`}>Mixed Media</Link></li>
      </ul>);
  }
  
  render() {
    return (
        <div className="container">
            <div className="header row">
                {
                    isMobile ? 
                        <Fragment>
                            <div className="header__text">
                                <h1><Link to="/">Bearded Fellows</Link></h1>
                            </div>
                            <div className="header__menu">
                                <ul>
                                    <li><Link to="/"  className={`underline ${this.selectedMenuItem.work}`}>Work</Link></li>
                                    <li><Link to="/about" className={`underline ${this.selectedMenuItem.about}`}>About</Link></li>
                                </ul>
                            </div>
                        </Fragment>
                        :
                        <Fragment>
                            <div className="col-xs-12 col-sm header__menu">
                                <ul>
                                    <li><Link to="/about" className={`underline ${this.selectedMenuItem.about}`}>About</Link></li>
                                    <li><Link to="/"  className={`underline ${this.selectedMenuItem.work}`}>Work</Link></li>
                                </ul>
                            </div>
                            <div className="col-xs-12 header__text col-sm">
                                <h1><Link to="/">Bearded Fellows</Link></h1>
                            </div>
                            <div className="header__filters col-sm">
                                { this.renderFilters() }
                            </div>
                        </Fragment>
                }
            </div>
        </div>
    );
  }
}