import React, { Component } from 'react';

import ImageBox from './ImageBox';

import projects from "./projects.json";

class Album extends Component {

    constructor(props) {
      super(props);
  
      this.state = {
          projects: projects
      }
    }
  
    renderAlbum = ( items ) => { 
      return (
        <div className="row imageAlbum">
            {items.map( item => <ImageBox key={item.path} {...item} />)}
        </div>
      );
      
    }
  
    render () {
  
      // console.log( this.state.projects[0].sections[0].items );
      return (
        <div className="album__container">
            {this.renderAlbum(this.props.section.items)}
        </div>
      );
    }
  }
  
  

  export default Album;