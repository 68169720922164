import React from 'react'; 
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

const BeardedGallery = ({imageArray}) => {

    let imageItemsFormated = [];
    if( Array.isArray(imageArray) ){
        imageArray.map( (imagePath) => {
            imageItemsFormated.push({
                original: imagePath,
                thumbnail: imagePath
            });
            return null;
        });
    } else {
        console.log(imageArray);
    }

    let config = {
        infinite:   true,
        lazyLoad:   false,
        showNav:    true,
        showThumbnails: false,
        showFullscreenButton: false,
        showPlayButton: false,
        showBullets: true,
        swipingTransitionDuration: 100,
        slideInterval: 2000
    };

    return <ImageGallery items={imageItemsFormated} {...config} />; 
}

export default BeardedGallery;