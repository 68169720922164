import React, { Component } from 'react'; 
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

export default class VideoGallery extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showIndex: false,
            showBullets: false,
            infinite: true,
            showThumbnails: false,
            showFullscreenButton: false,
            showGalleryFullscreenButton: false,
            showPlayButton: false,
            showGalleryPlayButton: false,
            showNav: false,
            isRTL: false,
            slideDuration: 150,
            slideInterval: 12000,
            slideOnThumbnailOver: false,
            thumbnailPosition: 'bottom',
            showVideo: {},
        };
    
        this.itemArray = this.props.itemArray;
        this.images = [];
        if( Array.isArray(this.itemArray) ){
            this.itemArray.map((item) => {
                this.images.push({
                    original: item.screenshot,
                    embedUrl: item.url, 
                    renderItem: this._renderVideo.bind(this)
                })
            });
        } 
    }
  
    componentDidUpdate(prevProps, prevState) {
      if (this.state.slideInterval !== prevState.slideInterval ||
          this.state.slideDuration !== prevState.slideDuration) {
        // refresh setInterval
        this._imageGallery.pause();
        this._imageGallery.play();
      }
    }
  
    _onImageClick(event) {
      console.debug('clicked on image', event.target, 'at index', this._imageGallery.getCurrentIndex());
    }
  
    _onImageLoad(event) {
      console.debug('loaded image', event.target.src);
    }
  
    _onSlide(index) {
      this._resetVideo();
      console.debug('slid to index', index);
    }
  
    _onPause(index) {
      console.debug('paused on index', index);
    }
  
    _onScreenChange(fullScreenElement) {
      console.debug('isFullScreen?', !!fullScreenElement);
    }
  
    _onPlay(index) {
      console.debug('playing from index', index);
    }
  
    _handleInputChange(state, event) {
      this.setState({[state]: event.target.value});
    }
  
    _handleCheckboxChange(state, event) {
      this.setState({[state]: event.target.checked});
    }
  
    _handleThumbnailPositionChange(event) {
      this.setState({thumbnailPosition: event.target.value});
    }
  
    _resetVideo() {
      this.setState({showVideo: {}});
  
      if (this.state.showPlayButton) {
        this.setState({showGalleryPlayButton: true});
      }
  
      if (this.state.showFullscreenButton) {
        this.setState({showGalleryFullscreenButton: true});
      }
    }
  
    _toggleShowVideo(url) {
      // this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);

      let tempState = {...this.state};
      tempState.showVideo[url] = !Boolean(this.state.showVideo[url]);
      
      this.setState({
        showVideo: tempState.showVideo
      });
  
      if (this.state.showVideo[url]) {
        if (this.state.showPlayButton) {
          this.setState({showGalleryPlayButton: false});
        }
  
        if (this.state.showFullscreenButton) {
          this.setState({showGalleryFullscreenButton: false});
        }
      }
    }
  
    _renderVideo(item) {
      return (
        <div className='image-gallery-image'>
          {
            this.state.showVideo[item.embedUrl] ?
              <div className='video-wrapper vimeo-wrapper'>
                  <iframe
                    width='640'
                    height='360'
                    src={`${item.embedUrl}`}
                    frameBorder='0'
                    allowFullScreen
                  >
                  </iframe>
              </div>
            :
              <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
                <div className='play-button'><i className="fa fa-play" /></div>
                <img src={item.original} alt="" />
                {
                  item.description &&
                    <span
                      className='image-gallery-description'
                      style={{right: '0', left: 'initial'}}
                    >
                      {item.description}
                    </span>
                }
              </a>
          }
        </div>
      );
    }
  
    render() {
      
      let currentIndex = this._imageGallery ? this._imageGallery.getCurrentIndex() : 0;
      console.log("CURRENT INDEX=", currentIndex);

      return (
        <div className="video-gallery">
            <div className="video-gallery__container">
                <ImageGallery
                    ref={i => this._imageGallery = i}
                    items={this.images}
                    lazyLoad={false}
                    onClick={this._onImageClick.bind(this)}
                    onImageLoad={this._onImageLoad}
                    onSlide={this._onSlide.bind(this)}
                    onPause={this._onPause.bind(this)}
                    onScreenChange={this._onScreenChange.bind(this)}
                    onPlay={this._onPlay.bind(this)}
                    infinite={this.state.infinite}
                    showBullets={this.state.showBullets}
                    showFullscreenButton={this.state.showFullscreenButton && this.state.showGalleryFullscreenButton}
                    showPlayButton={this.state.showPlayButton && this.state.showGalleryPlayButton}
                    showThumbnails={this.state.showThumbnails}
                    showIndex={this.state.showIndex}
                    showNav={this.state.showNav}
                    isRTL={this.state.isRTL}
                    thumbnailPosition={this.state.thumbnailPosition}
                    slideDuration={parseInt(this.state.slideDuration)}
                    slideInterval={parseInt(this.state.slideInterval)}
                    slideOnThumbnailOver={this.state.slideOnThumbnailOver}
                    additionalClass="app-image-gallery"
            />
            </div>
            <div className="video-gallery__nav">
                <ul>
                {
                    this.itemArray.map( (item, index) => {

                        let activeClass = currentIndex === index ? 'active' : '';
                        return (
                            <li key={`${index}_${item.title}`}>
                                <a className={`orange underline ${activeClass}`}
                                    onClick={() => this._imageGallery.slideToIndex(index)} title={item.title}>
                                    {item.title}
                                </a>
                            </li>
                        );
                    })
                }
                </ul>
            </div>
        </div>
      );
    }
  }